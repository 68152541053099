<template>
	<div class="page">
		<van-nav-bar title="Chọn vợ" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<p class="name">{{ xuanfeidata.text }}</p>
			<!-- <p class="title">{{ this.xuanfeidata.vo_title }}</p> -->
			<!-- <van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" /> -->
			<van-image width="98%" fit="contain" height="100%" :key="k" :src="xuanfeidata.img" />
			<van-button round="true" @click="yuyue" class="button" color="linear-gradient(90deg,#775fd9,#c24491)">Dự trữ</van-button>
		</div>
	</div>
</template>

<script>
import p1 from "../../assets/img/1.png"
import p2 from "../../assets/img/2.png"
import p3 from "../../assets/img/3.png"
import p4 from "../../assets/img/4.png"
import p5 from "../../assets/img/5.png"
import p6 from "../../assets/img/6.png"
import p7 from "../../assets/img/7.png"
import p8 from "../../assets/img/8.png"
import p9 from "../../assets/img/9.png"
import p10 from "../../assets/img/10.png"
import p11 from "../../assets/img/11.png"
import p12 from "../../assets/img/12.png"
import p13 from "../../assets/img/13.png"
export default {
	data() {
		return {
			xuanfeidata: [],
      list: [
        {
          img: p1,
          text: "A001",
          id: 1
        },
        {
          img: p2,
          text: "B001",
          id: 2
        },
        {
          img: p3,
          text: "C001",
          id: 3
        },
        {
          img: p4,
          text: "D001",
          id: 4
        },
        {
          img: p5,
          text: "E001",
          id: 5
        },
        {
          img: p6,
          text: "F001",
          id: 6
        },
        {
          img: p7,
          text: "G001",
          id: 7
        },
        {
          img: p8,
          text: "H001",
          id: 8
        },
        {
          img: p9,
          text: "I001",
          id: 9
        },
        {
          img: p10,
          text: "J001",
          id: 10
        },
        {
          img: p11,
          text: "K001",
          id: 11
        },
        {
          img: p12,
          text: "L001",
          id: 12
        },
        {
          img: p13,
          text: "M001",
          id: 13
        },
        
      ],
		};
	},
	methods: {
		back() {
			// this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
			this.$router.push({ path: 'Choose' });
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
			});
		},
		yuyue() {
			this.$toast('Vui lòng liên hệ với nhân viên tư vấn hoặc tiếp tân của chúng tôi');
		},
	},
	created() {
    this.xuanfeidata = this.list.find(item => item.id == this.$route.query.id)
	}
};
</script>

<style>
.container {
	display: inline-block;
}
.box {
	width: 100%;
	margin: 0 auto;
  height: 100%;
	text-align: center;
	padding-bottom: 6.25rem;
  height: 100%;
  background: #fff;
  margin-top: -30px;
}
.name {
	font-size: 1.125rem;
  color: #c24491;
  transform: translateY(20px);
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
</style>
