<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" title="Chọn vợ lẽ" />
    <div class="convention-item">
      <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab title="Xinh đẹp">
          <div class="card">
            Nền tảng này là một nền tảng hẹn hò quốc gia, kết nối các đối tác tình dục. Để đảm bảo quyền riêng tư của mỗi người dùng, khách hàng chỉ có thể tham gia bằng cách liên hệ với quầy lễ tân hoặc hiển thị tên thật của thành viên cấp cao của nền tảng.</div>
          <div class="address">
            <van-row
              type="flex"
              justify=""
              gutter="65"
              v-for="(v, key) in list"
              :key="key"
            >
              <van-col
                span=""
                offset=""
                @click="addgo(v)"
                style="width: 100%"
                >
                <p class="addname">{{ v.text }}</p>
                <img style="width: 100%; height 50px" :src="v.img" alt="" />
                </van-col
              >
            </van-row>
          </div>
        </van-tab>
        <van-tab title="Lịch sử">
          <div class="card">
            Nền tảng này là xung quanh+hộ tống thương mại+đam mê
            Trong cùng một thành phố, thông qua toàn bộ mạng lưới, đảm bảo
            Quyền riêng tư của mỗi người dùng, khách hàng chỉ có thể tham gia
            Bằng cách liên hệ với nhân viên lễ tân hoặc giới thiệu tên thật của thành phố
            Thành viên cao cấp của Foundation.
          </div>
          <div class="rig-box">
            <p class="rig-title">Tài nguyên có sẵn?</p>
            <p class="rig-content">
              đỏ, mô hình, tiếp viên hàng không, nhân vật Chàng trai trẻ, sinh viên đại học, chỉ có những điều bạn không ngờ tới.Bạn không thể làm điều đó mà không có nền tảng này.
            </p>
            <p class="rig-title">Khu vực dịch vụ?</p>
            <p class="rig-content">
              Các cuộc hẹn miễn phí tại cùng một thành phố, bất cứ nơi nào trong cả nước, các thành phố loại I và loại III tại Việt Nam cũng có thể được sắp xếp bằng cách liên hệ với quầy lễ tân.
            </p>
            <p class="rig-content">
              Nền tảng này là một nền tảng hẹn hò quốc gia, kết nối các đối tác tình dục. Để đảm bảo quyền riêng tư của mỗi người dùng, khách hàng chỉ có thể tham gia bằng cách liên hệ với quầy lễ tân hoặc hiển thị tên thật của thành viên cấp cao của nền tảng.</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import p1 from "../../assets/img/1.png"
import p2 from "../../assets/img/2.png"
import p3 from "../../assets/img/3.png"
import p4 from "../../assets/img/4.png"
import p5 from "../../assets/img/5.png"
import p6 from "../../assets/img/6.png"
import p7 from "../../assets/img/7.png"
import p8 from "../../assets/img/8.png"
import p9 from "../../assets/img/9.png"
import p10 from "../../assets/img/10.png"
import p11 from "../../assets/img/11.png"
import p12 from "../../assets/img/12.png"
import p13 from "../../assets/img/13.png"
export default {
  data() {
    return {
      list: [
        {
          img: p1,
          text: "A001",
          id: 1
        },
        {
          img: p2,
          text: "B001",
          id: 2
        },
        {
          img: p3,
          text: "C001",
          id: 3
        },
        {
          img: p4,
          text: "D001",
          id: 4
        },
        {
          img: p5,
          text: "E001",
          id: 5
        },
        {
          img: p6,
          text: "F001",
          id: 6
        },
        {
          img: p7,
          text: "G001",
          id: 7
        },
        {
          img: p8,
          text: "H001",
          id: 8
        },
        {
          img: p9,
          text: "I001",
          id: 9
        },
        {
          img: p10,
          text: "J001",
          id: 10
        },
        {
          img: p11,
          text: "K001",
          id: 11
        },
        {
          img: p12,
          text: "L001",
          id: 12
        },
        {
          img: p13,
          text: "M001",
          id: 13
        },
        
      ],
      addlist: [
        {
         0 :'Beijing',
         1:'Shanghai',
         2:'Guangzhou',
         3: 'Shenzhen',
        },
        {
          0: "Nanjing",
          1: "School",
          2: "Chongqing",
          3: "Xi'an",
        },
      ],
    };
  },
  methods: {
    addgo(data) {
      this.$router.push({ path: '/profile?id=' + data.id });
      // if (!localStorage.getItem("token")) {
      //   this.$router.push({ path: "/Login" });
      // } else {
      //   this.$router.push({
      //     path: "/list?id=" + data.id + "&name=" + data.name,
      //   });
      // }
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "address_list",
      }).then((res) => {
        this.addlist = res.data;
        res['data'][0].forEach((item, index) => {
          this.getxuanfeilist(item.id, index)
        })
      });
    },
    getxuanfeilist(id, index) {
			this.$http({
				method: 'get',
				url: 'xuanfeilist',
				data: {id}
			}).then(res => {
        this.$set(this.addlist[0][index], 'img', res.data)
        console.log(this.addlist)
			});
		}
  },
  created() {
    this.getAddress();
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-tab__text {
  color: #c24491;
}
::v-deep .van-tabs__line {
  background-color: #c24491 !important
}
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(90deg,#775fd9,#c24491);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}
.addname {
  text-align: center;
  transform: translateY(30px);
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}
::v-deep .van-tabs__line {
  background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.card {
  background-color: #775fd9;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 1rem;
  margin: 0.625rem auto;
  line-height: 1.5rem;
  border-radius: 0.375rem;
}
::v-deep .van-row--flex {
  // height: 80px;
  width: 100%;
  line-height: 80px;
}
::v-deep .van-col {
  // height: 80px;
  width: 100%;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #c24491;
  font-size: 1.425rem;
}
.rig-content {
  font-size: 30px;
  color: #c24491;
  // margin-top: 10px;
}
::v-deep .van-col {
  color: #c24491;
}
.address {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 110px;
  box-sizing: border-box;
}
</style>
